import React from 'react';

import {
  Container,
  RopeContainer,
  Rope,
  Blockquote,
  MobileBlock,
} from './styles';

import Hero from '../Hero';
import Ship from '../Ship';
import Description from '../Description';
import Footer from '../Footer';

class App extends React.Component {
  render() {
    return (
      <Container>
        <RopeContainer>
          <Rope />
        </RopeContainer>
        <Hero />
        
        <Blockquote>
          Koe<MobileBlock> • Nauti • </MobileBlock>Purjehdi
        </Blockquote>
        
        <Ship />

        <Description />

        <Footer />
        
      </Container>
    );
  }
}

export default App;
