import styled from 'styled-components';

const Container = styled.footer`
  margin: 120px 0;
  padding: 120px 0 0;
  background-image: url(${props => props.bg});
  background-position: 50% 0;
  background-repeat: repeat-x;

  a {
    color: #488CA5;
  }

  em {
    color: rgba(2,95,123,0.6);
  }

  @media (max-width: 480px) {
    margin: 64px 0;
    padding: 80px 0 0;
    background-size: 150% auto;
  }
`;

const Row = styled.div`
  max-width: 1440px;
  width: 70vw;
  margin: 120px auto 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 1200px) {
    width: 85vw;
  }

  @media (max-width: 480px) {
    margin: 40px auto;
  }
`;

const Column = styled.div`
  flex-basis: 33%;

  @media (max-width: 1200px) {
    margin: 0 0 24px;
  }

  @media (max-width: 480px) {
    flex-basis: 100%;
  }
`;

const Heading = styled.h3`
`;

const Price = styled.span`
  font-size: 32px;
  font-weight: 600;
`;

const Reservation = styled.p`
  margin: 32px 0;
  flex-basis: 66%;

  @media (max-width: 1200px) {
    margin: 0;
  }

  @media (max-width: 480px) {
    flex-basis: 100%;
  }
`;

export {
  Container,
  Row,
  Column,
  Heading,
  Price,
  Reservation,
}