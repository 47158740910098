import React from 'react';
import ImageZoom from 'react-medium-image-zoom'

import {
  Container,
  Row,
  Column,
  Heading,
  Price,
  Reservation,
} from './styles';

import thumb from './image/320w.jpg';
import zoom from './image/1200w.jpg';
import WavesSvg from '../../assets/waves.svg';

const Footer = () => {
  return (
    <Container id="hinnasto" bg={WavesSvg}>
      <Row>
        <Column>
          <Heading>Hinnasto</Heading>
          <p>
            <strong>Vene ja kippari</strong><br />
            <Price>750€</Price> /päivä, ALV 0%<br />
            <em>Ei sisällä aterioita, majoitusta</em>
          </p>
        </Column>

        <Column>
          <Heading>Yhteystiedot</Heading>
          <p>
            <strong>Sauli Hannuksela</strong><br />
            Estrella de mar<br />
            <a href="mailto:hannukselasauli@gmail.com">hannukselasauli@gmail.com</a><br />
            <a href="tel:0400 121 857">0400 121 857</a>
          </p>
        </Column>

        <Column>
          <ImageZoom
            image={{
              src: thumb,
              alt: 'Sauli Hannuksela',
              style: {
                borderRadius: '4px',
                height: '256px',
                boxShadow: '0px 8px 12px -4px rgba(33,33,33,0.2), 0px 24px 120px -40px rgba(61,61,61,0.6)',
              },
            }}
            zoomImage={{
              src: zoom,
              alt: 'Sauli Hannuksela',
            }}
          />
        </Column>
        <Reservation>
          <em>Varaus vahvistuu, kun varausmaksusta 1/3 on maksettu. Varausmaksua ei palauteta, mikäli peruuntuminen tapahtuu 0-14 vrk ennen purjehdusta.</em>
        </Reservation>
      </Row>
    </Container>
  )
}


export default Footer
