import React from 'react';
import {
  Container,
  Bg,
  Header,
  Nav,
  NavLink,
  Content,
  Heading,
  Paragraph,
  Anchor,
} from './styles';

import w640 from './bg/640w.jpg';
import w960 from './bg/960w.jpg';
import w1200 from './bg/1200w.jpg';
import w1600 from './bg/1600w.jpg';

import Wave from '../../assets/white-wave-top.svg';

const Hero = () => {
  return (
    <Container bg={Wave}>
      <Bg
        sizes="(max-width: 640px) 640px, (max-width: 960px) 960px, (max-width: 1200px) 1200px, (min-width: 1200px) 1600px"
        srcSet={`${w640} 640w, ${w960} 960w, ${w1200} 1200w, ${w1600} 1600w`}
        src={w1200}
        alt="Estrella de mar ilta-aallloilla"
      />
      <Header>
        <Nav>
          <NavLink href="#estrella-de-mar">Estrella de mar</NavLink>
          <NavLink href="#purjehdus">Purjehdus</NavLink>
          <NavLink href="#hinnasto">Hinnasto</NavLink>
        </Nav>
      </Header>

      <Content>
        <Heading>Saaristomeri kutsuu!</Heading>
        <Paragraph>Koe unohtumaton elämyspurjehdus oman miehistösi ja kokeneen kipparin kanssa.</Paragraph>
        <Paragraph>Tehdään yhdessä juuri sinun yrityksesi näköinen purjehdus!</Paragraph>
      </Content>

      <Anchor />
    </Container>
  )
}

export default Hero