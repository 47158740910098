import React from 'react';
import ImageZoom from 'react-medium-image-zoom'

import {
  Container,
  Heading,
  Nowrap,
  Description,
  Image,
  Spec,
} from './styles';

import WaveTopSvg from '../../assets/blue-wave-top.svg';
import WaveBottomSvg from '../../assets/blue-wave-bottom.svg';

import small from './image/480w.jpg';
import medium from './image/640w.jpg';
import large from './image/900w.jpg';
import zoom from './image/zoom.jpg';

const Ship = () => {
  const iw = window.innerWidth;
  const image = iw > 1000 ? large : iw > 600 ? medium : small;
  return (
    <Container id="estrella-de-mar" top={WaveTopSvg} bottom={WaveBottomSvg}>
      <Heading>Estrella <Nowrap>de mar</Nowrap></Heading>

      <Image>
        <ImageZoom
          image={{
            src: image,
            alt: 'Estrella de mar',
            style: {
              width: '100%',
              marginBottom: '-2px',
              visibility: 'visible !important',
            }
          }}
          zoomImage={{
            src: zoom,
            alt: 'Estrella de mar',
          }}
        />
      </Image>

      <Description>
        Tiimisi purjehtii upealla, hyvinvarustetulla Finngulf 37 veneellä
        <Spec>Yrityspurjehdukset max. 6 henkilölle</Spec>
      </Description>
    </Container>
  )
}

export default Ship