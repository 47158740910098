import styled from 'styled-components';
import { ReactComponent as AnchorSvg } from '../../assets/anchor.svg';

const Container = styled.section`
  position: relative;
  min-height: 80vh;
  color: #F4FAFC;
  overflow: hidden;
  padding: 40vh 5vw 20vh;

  @media (max-width: 480px) {
    min-height: 90vh;
  }

  &:before {
    content: "";
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.8+100 */
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 ); /* IE6-9 */
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: 0;

    @media (max-width: 1200px) {
      height: 100%;
    }

    @media (max-width: 720px) {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.2+0,0.8+100 */
      background: -moz-linear-gradient(top, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33000000', endColorstr='#cc000000',GradientType=0 ); /* IE6-9 */
    }
  }

  &:after {
    content: "";
    background-image: url(${props => props.bg});
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 58px;
    background-repeat: repeat-x;
    background-position: 50% 0;
    /*animation: waves 6s infinite forwards linear;*/

    @media (max-width: 480px) {
      background-size: 150% 150%;
    }
  }

  @keyframes waves {
    0% {
      background-position: 50vw 0;
    }
    100% {
      background-position: -50vw 0;
    }
  }
`;

const Bg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: calc(100% - 1px);
  z-index: 0;
`;

const Header = styled.header`
  position: absolute;
  top: 40px;
  right: 40px;
  width: calc(100% - 80px);

  @media (max-width: 480px) {
    top: 24px;
    right: 24px;
    width: calc(100% - 48px);
  }
`;

const Nav = styled.nav`
  text-align: right;
`;

const NavLink = styled.a`
  display: inline-block;
  margin-left: 40px;
  color: white;
  text-decoration: none;
  text-shadow: 0px 2px 8px rgba(0,0,0,0.2);

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 720px) {
    text-shadow: 4px 4px 12px rgba(0,0,0,0.6);
  }

  @media (max-width: 480px) {
    margin: 0 0 16px 0;
    display: block;
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 11;
  text-shadow: 0px 2px 8px rgba(0,0,0,0.2);

  @media (max-width: 720px) {
    text-shadow: 4px 4px 12px rgba(0,0,0,0.6);
  }
`;

const Heading = styled.h1`
  position: relative;
  margin-bottom: 40px;

  @media (max-width: 350px) {
    margin-bottom: 20px;
  }
`;

const Paragraph = styled.p`
  width: 40vw;
  position: relative;
  font-size: 24px;
  margin-bottom: 40px;

  &:nth-last-child(1) {
    margin-bottom: 0px;
  }

  @media (max-width: 720px) {
    width: 80vw;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const Anchor = styled(AnchorSvg)`
  position: absolute;
  z-index: 9;
  bottom: 98px;
  left: calc(50% - 22px);
  animation: bounce 3s infinite forwards ease-out;

  @keyframes bounce {
    0% {
      bottom: 98px;
    }
    90% {
      bottom: 48px;
    }
    1090% {
      bottom: 98px;
    }
  }

  @media (max-width: 480px) {
    transform: scale(0.8);
    bottom: 78px;

    @keyframes bounce {
      0% {
        bottom: 78px;
      }
      90% {
        bottom: 20px;
      }
      1090% {
        bottom: 78px;
      }
    }
  }

  @media (max-width: 350px) {
    display: none;
  }
`;

export {
  Container,
  Bg,
  Header,
  Nav,
  NavLink,
  Content,
  Heading,
  Paragraph,
  Anchor,
}