import React from 'react';
import ImageZoom from 'react-medium-image-zoom'

import {
  Container,
  Images,
  Image,
  Heading,
  Content,
  Column,
  Number,
} from './styles';

import flag256w from './images/flag/256w.jpg';
import flag1200w from './images/flag/1200w.jpg';
import flag2000w from './images/flag/2000w.jpg';

import flags256w from './images/flags/256w.jpg';
import flags960w from './images/flags/960w.jpg';
import flags1200w from './images/flags/1200w.jpg';

import inside1_256w from './images/inside1/256w.jpg';
import inside1_1200w from './images/inside1/1200w.jpg';
import inside1_2000w from './images/inside1/2000w.jpg';

import inside2_256w from './images/inside2/256w.jpg';
import inside2_1200w from './images/inside2/1200w.jpg';
import inside2_2000w from './images/inside2/2000w.jpg';

import ship256w from './images/ship1/256w.jpg';
import ship480w from './images/ship1/480w.jpg';

import wheel256w from './images/wheel/256w.jpg';
import wheel960w from './images/wheel/960w.jpg';
import wheel1600w from './images/wheel/1600w.jpg';

const Description = () => {
  const iw = window.innerWidth;
  const small = iw < 1100;

  const images = [
    {
      thumb: inside1_256w,
      zoom: small ? inside1_1200w : inside1_2000w,
      alt: 'Estrella de mar - Sisätila 1',
      style: {
        height: '100%',
        transform: 'translateX(-20%)',
        visibility: 'visible !important',
      }
    },
    {
      thumb: inside2_256w,
      zoom: small ? inside2_1200w : inside2_2000w,
      alt: 'Estrella de mar - Sisätila 2',
      style: {
        height: '100%',
        transform: 'translateX(-20%)',
        visibility: 'visible !important',
      }
    },
    {
      thumb: flags256w,
      zoom: small ? flags960w : flags1200w,
      alt: 'Ystävyysliput',
      style: {
        width: '100%',
        transform: 'translateY(-20%)',
        visibility: 'visible !important',
      }
    },
    {
      thumb: flag256w,
      zoom: small ? flag1200w : flag2000w,
      alt: 'Suomen lippu',
      style: {
        height: '100%',
        visibility: 'visible !important',
      }
    },
    {
      thumb: ship256w,
      zoom: ship480w,
      alt: 'Estrella de mar iltavesillä',
      style: {
        width: '100%',
        transform: 'translatey(-20%)',
        visibility: 'visible !important',
      }
    },
    {
      thumb: wheel256w,
      zoom: small ? wheel960w : wheel1600w,
      alt: 'Sauli veneen ruorissa',
      style: {
        width: '100%',
        visibility: 'visible !important',
      }
    },
  ];
  
  return (
    <Container id="purjehdus">
      <Images>
        {
          images.map(image => (
            <Image key={image.alt}>
              <ImageZoom
                image={{
                  src: image.zoom,
                  alt: image.alt,
                  style: image.style,
                }}
                zoomImage={{
                  src: image.zoom,
                  alt: image.alt,
                }}
              />
            </Image>
          ))
        }
      </Images>
      <Heading>Haluatko nauttia laatuajasta tai oppia purjehtimista?</Heading>
      <Content>
        <Column>
          <p>Miehistölläsi on mahdollisuus osallistua purjehduksen manöövereihin ja päästä ruorin taakse kokemaan kuinka vene kulkee.</p>
          <p>Nauti tiimisi, yhteistyökumppaniesi tai asiakkaittesi kanssa päivästä merellä.</p>
          <p>Veneen kipparilla on vankka kokemus avomeripurjehduksesta valtamerellä vaativissa olosuhteissa.</p>
        </Column>

        <Column>
          <p>Ennen kuin köydet irroitetaan, kapteeni esittelee veneen miehistölle ja kertoo päivän tehtävistä, purjehdusreitistä, turvallisuudesta sekä turvallisuusvarusteista.</p>
          <p><strong>Turvallisuutesi on tärkeää!</strong></p>
        </Column>
      </Content>

      <Heading>Räätälöidään legit yhdessä!</Heading>
      <Content>
        <Column>
          <Number>1</Number>
          <p>
            <strong>Esimerkkejä yhden päivän purjehduksesta</strong><br />
            Raisio - Nauvo - Raisio<br />
            Raisio - Airisto Strand - Raisio
          </p>
        </Column>

        <Column>
          <Number>2</Number>
          <p>
            <strong>Esimerkkejä kahden päivän ja yhden yön purjehduksesta</strong><br />
            Raisio - Örö - Raisio<br />
            Raisio - Korpoström - Raisio
          </p>
        </Column>
      </Content>

    </Container>
  )
}

export default Description