import styled from 'styled-components';
import { ReactComponent as RopeSvg } from '../../assets/rope.svg';

const Container = styled.main`
  position: relative;
  height: 100%;
`;

const RopeContainer = styled.div`
  position: absolute;
  top: calc(70vh - 128px);
  right: 0px;
  height: 512px;
  width: calc(128px * 3);
  z-index: 10;
  overflow: hidden;
  pointer-events: none;

  @media (max-width: 1400px) {
    height: 444px;
    width: 300px;
  }

  @media (max-width: 1100px) {
    height: 333px;
    width: 250px;
  }

  @media (max-width: 480px) {
    top: calc(80vh - 64px);
    height: 263px;
    width: 250px;
  }
`;

const Rope = styled(RopeSvg)`
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
`;

const Blockquote = styled.blockquote`
  margin: 120px 0;

  font-size: 32px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 13px;
  z-index: 11;
  position: relative;

  @media (max-width: 720px) {
    font-size: 24px;
  }
`;

const MobileBlock = styled.span`
  @media (max-width: 480px) {
    display: block;
    margin: 20px 0;
  }
`;

export {
  Container,
  RopeContainer,
  Rope,
  Blockquote,
  MobileBlock,
}