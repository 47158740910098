import styled from 'styled-components';

const Container = styled.section`
  background-color: #E3F5FA;
  margin: 178px 0;
  padding: 80px 0;
  position: relative;

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 58px;
    background-repeat: repeat-x;
    background-position: 50% 0;

    @media (max-width: 480px) {
      background-size: 150% 150%;
    }
  }

  &:before {
    background-image: url(${props => props.top});
    bottom: 100%;
  }

  &:after {
    background-image: url(${props => props.bottom});
    top: 100%;
    background-position: 50% 100%;
  }

  @media (max-width: 720px) {
    margin: 178px 0 128px;
  }
`;

const Heading = styled.h2`
  text-align: center;
  margin: 0 0 40px;
  letter-spacing: 20px;
`;

const Nowrap = styled.span`
  white-space: nowrap;
`;

const Image = styled.div`
  display: block;
  width: 900px;
  margin: 0 auto 32px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 8px 8px -4px rgba(2,98,123,0.4), 0 24px 80px -24px rgba(2,95,123, 0.8);

  @media (max-width: 1024px) {
    width: 640px;
  }

  @media (max-width: 720px) {
    width: 480px;
  }

  @media (max-width: 512px) {
    width: 100vw;
    border-radius: 0px;
    box-shadow: 0 8px 8px -4px rgba(2,98,123,0), 0 24px 80px -24px rgba(2,95,123, 0);
  }
`;

const Description = styled.p`
  text-align: center;
`;

const Spec = styled.span`
  font-style: italic;
  color: rgba(2, 95, 123, 0.6);
  display: block;
`;

export {
  Container,
  Heading,
  Nowrap,
  Image,
  Description,
  Spec,
}