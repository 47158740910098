import styled from 'styled-components';

const Container = styled.section`
`;

const Images = styled.div`
  position: relative;
  height: 334px;
  padding: 64px 0;
  text-align: center;

  @media (max-width: 1024px) {
    height: 256px;
  }

  @media (max-width: 720px) {
    height: 478px;
  }
`;

const Image = styled.div`
  position: absolute;
  top: 64px;
  left: calc(50% - 128px);
  display: inline-block;
  overflow: hidden;
  width: 256px;
  height: 206px;
  box-shadow: 0px 8px 12px -4px rgba(33,33,33,0.2), 0px 24px 120px -40px rgba(61,61,61,0.6);
  border-radius: 4px;

  @media (max-width: 1200px) {
    width: 200px;
    height: 175px;
    left: calc(50% - 100px);
  }

  @media (max-width: 1024px) {
    width: 150px;
    height: 125px;
    left: calc(50% - 75px);
  }

  &:nth-child(1) {
    transform: translate(-175%, 25%);
    z-index: 6;
  }

  &:nth-child(2) {
    transform: translate(-105%, -25%);
    z-index: 5;
  }

  &:nth-child(3) {
    transform: translate(-35%, 25%);
    z-index: 4;
  }

  &:nth-child(4) {
    transform: translate(35%, -25%);
    z-index: 3;
  }

  &:nth-child(5) {
    transform: translate(105%, 25%);
    z-index: 2;
  }

  &:nth-child(6) {
    transform: translate(175%, -25%);
    z-index: 1;
  }

  @media (max-width: 720px) {
    &:nth-child(1) {
      transform: translate(-70%, 25%);
      z-index: 6;
    }
  
    &:nth-child(2) {
      transform: translate(0%, -25%);
      z-index: 5;
    }
  
    &:nth-child(3) {
      transform: translate(70%, 25%);
      z-index: 4;
    }
  
    &:nth-child(4) {
      transform: translate(-70%, 155%);
      z-index: 3;
    }
  
    &:nth-child(5) {
      transform: translate(0%, 205%);
      z-index: 2;
    }
  
    &:nth-child(6) {
      transform: translate(70%, 155%);
      z-index: 1;
    }
  }
`;

const Heading = styled.h3`
  text-align: center;
  margin: 80px auto;
  width: 70vw;

  @media (max-width: 1200px) {
    width: 75vw;
  }

  @media (max-width: 480px) {
    width: 90vw;
    margin: 40px auto;
    text-align: left;
  }
`;

const Content = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  width: 70vw;
  display: flex;
  flex-direction: row;

  @media (max-width: 1200px) {
    width: 75vw;
  }

  @media (max-width: 720px) {
    flex-direction: column;
  }

  @media (max-width: 480px) {
    width: 90vw;
  }
`;

const Column = styled.div`
  position: relative;
  margin-right: 40px;
  flex-basis: 50%;

  &:nth-last-child(1) {
    margin-right: 0px;
  }

  @media (max-width: 720px) {
    margin-right: 0px;
    margin-bottom: 40px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
`;

const Number = styled.h2`
  position: absolute;
  top: -16px;
  right: calc(100% + 8px);
  font-size: 120px;
  opacity: 0.1;

  @media (max-width: 800px) {
    font-size: 80px;
    top: 0;
  }

  @media (max-width: 480px) {
    display: none;
  }
`;

export {
  Container,
  Images,
  Image,
  Heading,
  Content,
  Column,
  Number,
}